import React from 'react';
import './SuccessPage.css';

const SuccessPage = () => {
  return (
    <div className="landing-page">
      <div className="logo-container">
        <div className="success-animation">
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
        </div> 
      </div>
      <h1 className="title">You just added J1be to your workspace !</h1>
      <h2 className="sub-title">Ask the JB-P Team for your access token</h2>
    </div>
  );
};

export default SuccessPage;
