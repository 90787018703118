import React from 'react';
import './LandingPage.css';
import logo from '../img/j1be.png';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <div className="logo-container">
        <img src={logo} alt="Logo J1BE" />
      </div>
      <h1 className="coming-soon-text">Something is coming soon...</h1>
    </div>
  );
};

export default LandingPage;
